import Vue from 'vue'
import I18n from 'vue-i18n'
/* Languages */
import Es from './es'

Vue.use(I18n)

const messages = {
  es: Es
}

export default new I18n({
  locale: 'es',
  messages
})

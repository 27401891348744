const Status = () =>
  import(/* webpackChunkName: "votings" */ '@/views/votings/Status.vue')

const Votings = () =>
  import(/* webpackChunkName: "votings" */ '@/views/votings/Votings.vue')

const Vote = () =>
  import(/* webpackChunkName: "votings" */ '@/views/votings/Vote.vue')

export default [
  {
    name: 'VotingStatus',
    path: '/votings/status',
    component: Status
  },
  {
    name: 'Votings',
    path: '/votings/voting',
    component: Votings
  },
  {
    name: 'Vote',
    path: '/votings/vote/:id',
    component: Vote
  }
]

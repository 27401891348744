import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import auth from '@/plugins/auth'
import { EventBus } from '@/plugins/event-bus'
import router from '@/router'
import resource from '@/resource'
import store from '@/store'
import i18n from '@/i18n'
import Paths from './public-paths.json'
import './registerServiceWorker'

Array.prototype.findPath = function(path) {
  let result = false
  for (let i = 0; i < this.length; i++) {
    var reg = new RegExp(this[i])
    var match = path.match(reg)
    if (match && path === match[0]) {
      console.warn(`the path ${path} is public`)
      result = true
      break
    }
  }
  return result
}

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  next()

  var loginPath = '/session/login'
  var logoutPath = '/session/logout'
  var publicPaths = Paths.concat([loginPath, logoutPath])

  var isLogged = localStorage.getItem('token') !== null || false
  var isPublic = publicPaths.findPath(to.path)

  /** Verificar si la URL es publica */
  if (!isPublic && !isLogged && to.path !== loginPath) {
    console.warn(`redirecting from: ${to.path} to ${loginPath}`)
    router.push(loginPath)
  }
})
/** Enviando token si existe */
Vue.http.interceptors.push((request, next) => {
  var token = localStorage.getItem('token')
  if (token !== null) {
    request.headers.set('Accept', 'application/json')
    request.headers.set('Authorization', `Bearer ${token}`)
    next()
  }
})
/** Refrescando Token */
let refreshTokenPromise = null
Vue.http.interceptors.push((request, next) => {
  next(response => {
    if (request.url === '/api/token/refresh/' && response.status !== 200) {
      console.warn('Token was expired!')
      localStorage.clear()
      router.go('/session/login')
    }

    if (response.status === 401) {
      if (!refreshTokenPromise) {
        refreshTokenPromise = auth.refreshToken().then(token => {
          console.warn('Token was refreshed!')
          refreshTokenPromise = null
          return token
        })
      }

      return refreshTokenPromise.then(result => {
        localStorage.setItem('token', result.data.access)
        return Vue.http(request).then(data => {
          return data
        })
      })
    }
  })
})

/** Capturando Errores en EventBus*/
Vue.http.interceptors.push((request, next) => {
  next(response => {
    if (response.status !== 200 && response.status !== 401) {
      var tittle = 'Server Error!'
      var message = 'Invalid request or internal server error'
      EventBus.$emit('dialog-error', tittle, message, response.body)
    }
  })
})

Vue.http.options.root = '/api'

new Vue({
  vuetify,
  router,
  resource,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

const Instructors = () =>
  import(
    /* webpackChunkName: "citizens_training" */ '@/views/citizens_training/Instructors.vue'
  )
const Categories = () =>
  import(
    /* webpackChunkName: "citizens_training" */ '@/views/citizens_training/Categories.vue'
  )
const Levels = () =>
  import(
    /* webpackChunkName: "citizens_training" */ '@/views/citizens_training/Levels.vue'
  )
const Locations = () =>
  import(
    /* webpackChunkName: "citizens_training" */ '@/views/citizens_training/Locations.vue'
  )
const Status = () =>
  import(
    /* webpackChunkName: "citizens_training" */ '@/views/citizens_training/Status.vue'
  )
const Types = () =>
  import(
    /* webpackChunkName: "citizens_training" */ '@/views/citizens_training/Types.vue'
  )
const Modes = () =>
  import(
    /* webpackChunkName: "citizens_training" */ '@/views/citizens_training/Modes.vue'
  )
const Trainings = () =>
  import(
    /* webpackChunkName: "citizens_training" */ '@/views/citizens_training/Trainings.vue'
  )
const ReportTrainings = () =>
  import(
    /* webpackChunkName: "citizens_training" */ '@/views/citizens_training/ReportTrainings.vue'
  )

const ViewTrainings = () =>
  import(
    /* webpackChunkName: "citizens_training" */ '@/views/citizens_training/ViewTrainings.vue'
  )

const EnrollForm = () =>
  import(
    /* webpackChunkName: "citizens_training" */ '@/views/citizens_training/EnrollForm.vue'
  )

export default [
  {
    name: 'Instructors',
    path: '/citizens_training/instructors',
    component: Instructors
  },
  {
    name: 'Categories',
    path: '/citizens_training/categories',
    component: Categories
  },
  {
    name: 'Levels',
    path: '/citizens_training/levels',
    component: Levels
  },
  {
    name: 'Locations',
    path: '/citizens_training/locations',
    component: Locations
  },
  {
    name: 'TrainingStatus',
    path: '/citizens_training/status',
    component: Status
  },
  {
    name: 'Types',
    path: '/citizens_training/types',
    component: Types
  },
  {
    name: 'Modes',
    path: '/citizens_training/modes',
    component: Modes
  },
  {
    name: 'Trainings',
    path: '/citizens_training/trainings',
    component: Trainings
  },
  {
    name: 'ReportTrainings',
    path: '/citizens_training/report_trainings',
    component: ReportTrainings
  },
  {
    name: 'ViewTrainings',
    path: '/citizens_training/view_trainings',
    component: ViewTrainings
  },
  {
    name: 'EnrollForm',
    path: '/citizens_training/training/:id',
    component: EnrollForm
  }
]

<template>
  <v-card class="pt-5 pr-10 pl-10" flat>
    <v-card-title v-if="is_authenticated">
      <p class="font-weight-bold text-h6">Hola {{ fullname }}!</p>
    </v-card-title>
    <v-expand-transition>
      <v-responsive>
        <p class="text-center font-weight-black text-h5">
          Bienvenido a
        </p>
        <p class="text-center font-weight-black text-h1">
          Apps Mosquera
        </p>
        <p class="text-center text--secondary font-italic text-h5">
          Aplicativos "In - House" de la Alcaldía de Mosquera
        </p>
      </v-responsive>
    </v-expand-transition>
    <v-row class="mt-16" dense v-if="false">
      <v-col cols="12" sm="6" md="6" class="pr-md-5">
        <v-card outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                Perfil
              </v-list-item-title>
              <v-list-item-subtitle>
                Administre sus datos, cambie su contraseña.
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="70" color="grey">
              <v-icon dark x-large>
                mdi-account
              </v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
            <v-btn outlined text>
              VER PERFIL
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" class="pl-md-5">
        <v-card outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                Documentación
              </v-list-item-title>
              <v-list-item-subtitle>
                Aprenda y entienda el sistema
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="70" color="grey">
              <v-icon dark x-large>
                mdi-book-open
              </v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
            <v-btn outlined text>
              VER DOCUMENTACIÓN
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'Dashboard',
  data: () => ({
    url_menu: null,
    url_profile: null,
    username: null,
    fullname: null,
    is_authenticated: false
  }),

  created() {
    this.is_authenticated = localStorage.getItem('token')
    this.url_profile = 'user/profile/'
    this.getProfile()
  },

  methods: {
    getProfile() {
      if (this.is_authenticated) {
        var profile = localStorage.getItem('profile')
        if (!profile) {
          this.$http.get(this.url_profile).then(request => {
            localStorage.setItem('profile', JSON.stringify(request.data))
            this.username = request.data.username
            this.fullname = request.data.fullname
          })
        } else {
          var data = JSON.parse(profile)
          this.username = data.username
          this.fullname = data.fullname
        }
      }
    },

    goToRoute(newPath) {
      if (this.$router.currentRoute.path === newPath) {
        this.$router.go()
      } else {
        this.$router.push({ path: newPath })
      }
    }
  }
}
</script>

<style></style>

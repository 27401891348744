const Pending = () =>
  import(/* webpackChunkName: "victims" */ '@/views/victims/Pending.vue')

const Groups = () =>
  import(/* webpackChunkName: "victims" */ '@/views/victims/Groups.vue')

const Report = () =>
  import(/* webpackChunkName: "victims" */ '@/views/victims/Report.vue')

export default [
  {
    name: 'victim_pending',
    path: '/victims/pending',
    component: Pending
  },
  {
    name: 'victim_groups',
    path: '/victims/groups',
    component: Groups
  },
  {
    name: 'victims_report',
    path: '/victims/report',
    component: Report
  }
]

import Vue from 'vue'

export default {
  refreshToken: function() {
    return Vue.http({
      url: '/api/token/refresh/',
      method: 'POST',
      body: JSON.stringify({ refresh: localStorage.getItem('refresh') })
    })
  }
}

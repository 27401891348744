const Status = () =>
  import(
    /* webpackChunkName: "citizens_attention" */ '@/views/citizens_attention/Status.vue'
  )
const Processes = () =>
  import(
    /* webpackChunkName: "citizens_attention" */ '@/views/citizens_attention/Processes.vue'
  )

const Capture = () =>
  import(
    /* webpackChunkName: "citizens_attention" */ '@/views/citizens_attention/Capture.vue'
  )

export default [
  {
    name: 'AttentionStatus',
    path: '/citizens_attention/status',
    component: Status
  },
  {
    name: 'AttentionProcesses',
    path: '/citizens_attention/processes',
    component: Processes
  },
  {
    name: 'AttentionCapture',
    path: '/citizens_attention/capture',
    component: Capture
  }
]

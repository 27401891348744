import Vue from 'vue'
import VueRouter from 'vue-router'
import SessionRoutes from '@/router/session'
import SecurityRoutes from '@/router/security'
import ServicesRoutes from '@/router/services'
import SystemRoutes from '@/router/system'
import OrganizationsRoutes from '@/router/organizations'
import CitizensTrainingRoutes from '@/router/citizens_training'
import CitizensAttentionRoutes from '@/router/citizens_attention'
import VotingRoutes from '@/router/votings'
import VictimsRoutes from '@/router/victims'

Vue.use(VueRouter)
var allRoutes = []
allRoutes = allRoutes.concat(
  SessionRoutes,
  SecurityRoutes,
  ServicesRoutes,
  SystemRoutes,
  OrganizationsRoutes,
  CitizensTrainingRoutes,
  CitizensAttentionRoutes,
  VotingRoutes,
  VictimsRoutes
)

const routes = allRoutes

const router = new VueRouter({
  routes
})

export default router

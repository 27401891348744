const Institutions = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/organizations/Institutions.vue'
  )
const Dependencies = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/organizations/Dependencies.vue'
  )
const Municipalities = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/organizations/Municipalities.vue'
  )

const Sectors = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/organizations/Sectors.vue'
  )
const Neighborhoods = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/organizations/Neighborhoods.vue'
  )

export default [
  {
    name: 'Institutions',
    path: '/organizations/institutions',
    component: Institutions
  },
  {
    name: 'Dependencies',
    path: '/organizations/dependencies',
    component: Dependencies
  },
  {
    name: 'Municipalities',
    path: '/organizations/municipalities',
    component: Municipalities
  },
  {
    name: 'Sectors',
    path: '/organizations/sectors',
    component: Sectors
  },
  {
    name: 'Neighborhoods',
    path: '/organizations/neighborhoods',
    component: Neighborhoods
  }
]

<template>
  <v-app-bar color="teal" dark app>
    <v-app-bar-nav-icon @click="showMenu()"></v-app-bar-nav-icon>
    <v-toolbar-title>Apps Mosquera</v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppHeader',
  methods: {
    showMenu() {
      this.$emit('show-menu')
    }
  }
}
</script>

<style scoped></style>

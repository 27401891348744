<template>
  <v-app class="mx-auto overflow-hidden" height="400" app>
    <AppHeader v-on:show-menu="showMenu" />
    <AppMenu ref="navigationDrawer" />
    <AppDialogError ref="dialogError" />
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
    <AppFooter />
  </v-app>
</template>

<script>
import AppHeader from '@/components/navigation/Header'
import AppFooter from '@/components/navigation/Footer'
import AppMenu from '@/components/navigation/Menu'
import AppDialogError from '@/components/dialogs/Error'
import { EventBus } from '@/plugins/event-bus'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    AppMenu,
    AppDialogError
  },
  data: () => ({
    drawer: false,
    group: null
  }),
  created() {
    EventBus.$on('dialog-error', (tittle, message, description) => {
      this.$refs.dialogError.open(tittle, message, description)
    })
  },
  methods: {
    showMenu() {
      this.$refs.navigationDrawer.open()
    }
  }
}
</script>

<style></style>

<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="1000px"
      persistent
      @keydown.enter="validate"
    >
      <v-row no-gutters>
        <v-col cols="12" md="7" sm="5" class="d-none d-sm-flex">
          <v-img src="@/assets/background.png" style="opacity: 0.7" content>
          </v-img>
        </v-col>
        <v-col cols="12" md="5" sm="7">
          <v-card flat tile class="pa-10">
            <v-card-title>
              {{ $t('operations.login') }}
            </v-card-title>
            <v-card-text>
              <v-form
                ref="loginForm"
                class="pt-10"
                v-model="valid"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      autofocus
                      v-model="user"
                      :rules="UserRules"
                      autocomplete="off"
                      required
                    >
                      <v-icon slot="prepend" color="teal" large
                        >mdi-account</v-icon
                      >
                      <template v-slot:label>{{ $t('fields.user') }} </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      type="password"
                      v-model="password"
                      :rules="[rules.required, rules.min]"
                      autocomplete="off"
                      required
                    >
                      <v-icon slot="prepend" color="teal" large
                        >mdi-lock</v-icon
                      >
                      <template v-slot:label
                        >{{ $t('fields.password') }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col />
                  <v-spacer></v-spacer>
                  <v-col class="d-flex" cols="12">
                    <v-btn
                      block
                      class="white--text"
                      color="teal"
                      :disabled="!valid"
                      @click="validate"
                    >
                      {{ $t('operations.enter') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: true,
    valid: true,
    password: '',
    user: '',
    UserRules: [(v) => !!v || 'Required'],
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => (v && v.length >= 8) || 'Min 8 characters'
    }
  }),
  methods: {
    validate() {
      if (this.$refs.loginForm.validate()) {
        // submit form to server/API here...
        var data = { username: this.user, password: this.password }
        this.$http
          .post('token/', data)
          .then((request) => {
            localStorage.setItem('token', request.data.access)
            localStorage.setItem('refresh', request.data.refresh)
            this.$router.push({ path: '/' })
          })
          .catch(() => this.$refs.loginForm.reset())
      }
    }
  }
}
</script>

<style>
</style>

const DocumentTypes = () =>
  import(/* webpackChunkName: "system" */ '@/views/system/DocumentTypes.vue')
const IdentificationTypes = () =>
  import(
    /* webpackChunkName: "system" */ '@/views/system/IdentificationTypes.vue'
  )
const RequerimentTypes = () =>
  import(/* webpackChunkName: "system" */ '@/views/system/RequerimentTypes.vue')
const InstitutionTypes = () =>
  import(/* webpackChunkName: "system" */ '@/views/system/InstitutionTypes.vue')
const Sexes = () =>
  import(/* webpackChunkName: "system" */ '@/views/system/Sexes.vue')
const GenderIdentities = () =>
  import(/* webpackChunkName: "system" */ '@/views/system/GenderIdentities.vue')
const SexualOrientations = () =>
  import(
    /* webpackChunkName: "system" */ '@/views/system/SexualOrientations.vue'
  )
const EthnicGroups = () =>
  import(/* webpackChunkName: "system" */ '@/views/system/EthnicGroups.vue')

const Handicaps = () =>
  import(/* webpackChunkName: "system" */ '@/views/system/Handicaps.vue')

const Formalities = () =>
  import(/* webpackChunkName: "system" */ '@/views/system/Formalities.vue')

const Conditions = () =>
  import(/* webpackChunkName: "system" */ '@/views/system/Conditions.vue')

const AgeGroups = () =>
  import(/* webpackChunkName: "system" */ '@/views/system/AgeGroups.vue')

export default [
  {
    name: 'DocumentTypes',
    path: '/system/document_types',
    component: DocumentTypes
  },
  {
    name: 'IdentificationTypes',
    path: '/system/identification_types',
    component: IdentificationTypes
  },
  {
    name: 'RequerimentTypes',
    path: '/system/requeriment_types',
    component: RequerimentTypes
  },
  {
    name: 'InstitutionTypes',
    path: '/system/institution_types',
    component: InstitutionTypes
  },
  {
    name: 'Sexes',
    path: '/system/sexes',
    component: Sexes
  },
  {
    name: 'GenderIdentities',
    path: '/system/gender_identities',
    component: GenderIdentities
  },
  {
    name: 'SexualOrientations',
    path: '/system/sexual_orientations',
    component: SexualOrientations
  },
  {
    name: 'EthnicGroups',
    path: '/system/ethnic_groups',
    component: EthnicGroups
  },
  {
    name: 'Handicaps',
    path: '/system/handicaps',
    component: Handicaps
  },
  {
    name: 'Formalities',
    path: '/system/formalities',
    component: Formalities
  },
  {
    name: 'Conditions',
    path: '/system/conditions',
    component: Conditions
  },
  {
    name: 'AgeGroups',
    path: '/system/age_groups',
    component: AgeGroups
  }
]

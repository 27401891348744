<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    ref="dialog"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">
          {{ tittle }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4">
        {{ message }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pt-0">
        <v-btn text @click="show = !show">
          <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          {{ $t('operations.more_info') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="cancel">Ok</v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-card-text>
            <p>{{ description }}</p>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    name: 'AppDialogError',
    dialog: false,
    show: false,
    resolve: null,
    reject: null,
    tittle: null,
    message: null,
    description: null,
    options: {
      color: 'red',
      width: 500,
      zIndex: 200
    }
  }),

  methods: {
    open(tittle, message, description) {
      this.dialog = true
      this.show = false
      this.tittle = tittle
      this.message = message
      this.description = description
      this.options = Object.assign(this.options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

<template>
  <v-navigation-drawer
    v-model="drawer"
    @keydown.esc="drawer = false"
    v-bind:width="350"
    temporary
    fixed
  >
    <v-list-item link v-if="is_authenticated">
      <v-list-item-avatar color="teal">
        <v-icon dark> mdi-account </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ fullname }}</v-list-item-title>
        <v-list-item-subtitle>{{ username }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list>
      <v-list-item @click="goToRoute('/')" link>
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ $t('modules.home') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-group
        v-for="(item, index) in items"
        :key="index"
        :value="item.selected"
        no-action
        dense
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(`modules.${item.name}`) }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:prependIcon>
          <v-icon> {{ item.icon }}</v-icon>
        </template>

        <v-list-item
          v-for="(option, i) in item.options"
          :key="i"
          @click="goToRoute(`/${item.name}/${option.name}`)"
          link
        >
          <v-list-item-title>
            {{ $t(`options.${item.name}.${option.name}`) }}
          </v-list-item-title>
          <v-list-item-icon>
            <v-icon>{{ option.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </v-list>
    <template v-slot:append v-if="is_authenticated">
      <v-divider></v-divider>
      <v-list-item @click="logout()" link>
        <v-list-item-title>
          {{ $t('operations.logout') }}
        </v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-exit-to-app</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'AppMenu',
  data: () => ({
    url_menu: null,
    url_profile: null,
    is_authenticated: null,
    drawer: false,
    resolve: null,
    reject: null,
    items: [],
    username: null,
    fullname: null
  }),
  created() {
    this.url_menu = 'user/menu/'
    this.url_profile = 'user/profile/'
  },
  methods: {
    open() {
      this.items = []
      this.is_authenticated = localStorage.getItem('token')
      this.drawer = true
      this.getItems()
      this.getProfile()
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    getProfile() {
      if (this.is_authenticated) {
        var profile = localStorage.getItem('profile')
        if (!profile) {
          this.$http.get(this.url_profile).then((request) => {
            localStorage.setItem('profile', JSON.stringify(request.data))
            this.username = request.data.username
            this.fullname = request.data.fullname
          })
        } else {
          var data = JSON.parse(profile)
          this.username = data.username
          this.fullname = data.fullname
        }
      }
    },

    getItems() {
      if (this.is_authenticated) {
        var menu = localStorage.getItem('menu')
        if (!menu) {
          this.$http.get(this.url_menu).then((request) => {
            localStorage.setItem('menu', JSON.stringify(request.data))
            this.items = request.data
          })
        } else {
          this.items = JSON.parse(menu)
        }
      }
    },

    goToRoute(newPath) {
      if (this.$router.currentRoute.path === newPath) {
        this.$router.go()
      } else {
        this.$router.push({ path: newPath })
      }
    },
    logout() {
      localStorage.clear()
      this.$router.push({ path: '/session/login' })
    }
  }
}
</script>
